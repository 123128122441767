
















































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { FCTab, FCTabs } from '@fc/angie-ui';
import BundleGroupCard from '~/components/marketplace/BundleGroupCard.vue';
import AccessoryCard from '~/components/marketplace/AccessoryCard.vue';
import PackagePreview from '~/components/checkout/components/PackagePreview.vue';
import { useCartContext } from '~/composables/useCartContext';
import { useToggleDrawer } from '~/composables/useToggleDrawer';
import { useProductsToDisplay, IActiveGroup } from './useProductsToDisplay';

export default defineComponent({
  name: 'MarketplaceCollectionContainer',
  components: {
    BundleGroupCard,
    AccessoryCard,
    PackagePreview,
    FCTab,
    FCTabs,
  },
  props: {
    inStockProducts: {
      type: Array as PropType<IActiveGroup[]>,
      required: true,
    },
  },
  setup(props) {
    // @ts-ignore
    const { $store } = useNuxtApp();

    const { activeGroup, activeItems, getProductsToDisplay } = useProductsToDisplay(props.inStockProducts, $store);
    const { packageInCartName, isCartEmpty } = useCartContext();
    const { openDrawer } = useToggleDrawer('mobileOnly');

    const isPackageInCart = computed(() => packageInCartName.value ?? $store.getters?.cartProducts?.packageInCart ?? false);

    return {
      getProductsToDisplay,
      activeGroup,
      activeItems,
      isBundleGroup: (section: string) => section === 'bundle',
      isAccessoryGroup: (section: string) => section === 'accessory',
      displayPackagePreview: isPackageInCart,
      isCartEmpty,
      openDrawer,
    };
  },
});
