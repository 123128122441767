import { ref, Ref, computed } from '@vue/composition-api';
import { IProduct, FCProduct } from '~/types/fightcamp';
import bundlesData from '~/components/products/content/bundles';
import fightCampProducts from '@/shared/datasets/products';
import { accessoriesContent } from '~/components/products/content/accessories';
import { Store } from 'vuex';
import { IStore } from '~/selectors/selectors';

export interface IActiveGroup {
  title: string;
  sectionType: 'bundle' | 'accessory';
  products: string[];
  shopifyProducts: any;
}

const SIZED_ITEMS = [
  'fightcamp_gloves_v2',
  'fightcamp_quickwraps_v2',
  'fightcamp_quickwraps_3pack',
  'fightcamp_full_zip_hoodie',
  'fightcamp_logo_hoodie_charcoal_heather',
  'fightcamp_logo_hoodie_oatmeal',
  'fightcamp_logo_tee_black',
  'fightcamp_mens_tank_black',
  'fightcamp_womens_tank_black',
];

export function useProductsToDisplay(products: IActiveGroup[], store?: Store<IStore>) {
  const activeGroup: Ref<IActiveGroup> = ref(products[0]);
  const activeItems: Ref<FCProduct[]> = ref([]);

  const isShopifyOn = computed(() => store?.getters?.isShopifyOn);
  const accessories: IProduct[] = Object.values(fightCampProducts).concat(accessoriesContent);

  function getProductsToDisplay(sectionTitle: { name: string }) {
    activeGroup.value = products.find((tab) => tab.title === sectionTitle.name) || products[0];

    if (isShopifyOn.value) {
      const groupLive = products.find((tab) => tab.title === sectionTitle.name) || products[0];
      activeItems.value = groupLive.shopifyProducts;
    } else if (activeGroup?.value?.sectionType === 'bundle') {
      activeItems.value = getBundleProducts(activeGroup);
    } else {
      activeItems.value = getAccessoriesProducts(activeGroup, accessories);
    }
  }

  return {
    activeGroup,
    activeItems,
    getProductsToDisplay,
  };
}

function getBundleProducts(activeGroup: Ref<IActiveGroup>): FCProduct[] {
  const bundleItems = Object.values(bundlesData).filter((bundle) => activeGroup.value.products.includes(bundle.item_id));
  const reorderedArray = activeGroup.value.products
    .map((id) => bundleItems.find((item) => item.item_id === id))
    .filter((item) => item !== undefined) as FCProduct[];

  return reorderedArray;
}

function getAccessoriesProducts(activeGroup: Ref<IActiveGroup>, accessories: IProduct[]): IProduct[] {
  const accessoryIds = Object.values(accessories)
    .filter((acc: IProduct) => (SIZED_ITEMS.includes(acc.id) ? (acc?.selects?.options?.length ?? 0) > 1 : true))
    .filter(acc => activeGroup?.value?.products?.includes(acc.id));

  return activeGroup.value.products.map(groupId => accessoryIds.find(accessory => accessory.id === groupId))
    .filter(item => item !== undefined) as IProduct[];
}
