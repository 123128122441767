import { computed } from '@vue/composition-api';
import { isTabletUpEffect, isMobileEffect, isDesktopEffect } from '~/effects/matchMedia';

type ViewPortOptions = 'mobileOnly' | 'tabletUpOnly' | 'desktopOnly' | 'all';

interface UseToggleDrawer {
  openDrawer: () => void;
  closeDrawer: () => void;
}

// TODO add test
export function useToggleDrawer(viewPort: ViewPortOptions = 'all'): UseToggleDrawer {
  // @ts-ignore
  const { $store } = useNuxtApp();

  const onlyForMobile = computed(() => viewPort === 'mobileOnly' && isMobileEffect().value);
  const onlyForTabletUp = computed(() => viewPort === 'tabletUpOnly' && isTabletUpEffect().value);
  const onlyForDesktop = computed(() => viewPort === 'desktopOnly' && isDesktopEffect().value);

  const activeFlag = computed(() => onlyForMobile.value || onlyForTabletUp.value || onlyForDesktop.value);

  function openDrawer() {
    if (viewPort === 'all') {
      $store.commit('toggleDrawer');
      return;
    }

    if (activeFlag.value) {
      $store.commit('toggleDrawer');
    }
  }

  return {
    openDrawer,
    closeDrawer: () => $store.commit('toggleDrawer'),
  };
}
